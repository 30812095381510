import { OptionType, ShopStatus } from "@/common/enum";
import { reactive, ref } from "vue";
import { UploadUrl } from "@/api/GoodsApi";
import { CheckShopName, CheckStoreNo, GetImageSize } from "@/common/utils";
import { ElMessage, ElMessageBox } from 'element-plus';
import { GoShopDetail, ShopSubmit, GiveUpChange, CanCelAudit } from '@/api/GoShopApi';
import { useRoute } from "vue-router";
import { FormatterDateTime } from "@/common/formatter";
import { Debounce } from "@/common/debounce";
import ShopService from "@/services/Shop/ShopService";
import { Shop } from "@/services/Shop/Shop";
import AccountService from "@/services/Account/AccountService";
export default function InfoEdit() {
    const uploadUrl = UploadUrl;
    const form = ref(null);
    const formObj = reactive(new Shop());
    const oldFormData = reactive(new Shop());
    const loading = ref(false);
    const disabled = ref(false);
    const router = useRoute();
    const rules = {
        name: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
    };
    const submit = () => {
        if ((formObj.storeStatus == ShopStatus.NoSaleVerifying) || (formObj.storeStatus == ShopStatus.SaleVerifying)) {
            // 撤销审核
            ElMessageBox.confirm("是否撤销审核？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                callback: (res) => {
                    if (res == "cancel") {
                        return ElMessage({
                            type: "info",
                            message: "取消撤销审核",
                        });
                    }
                    loading.value = true;
                    CanCelAudit({ id: formObj.id }).then((res) => {
                        loading.value = false;
                        getGoShopDetail();
                        ElMessage.success('操作成功');
                    });
                },
            });
            return;
        }
        loading.value = true;
        form.value.validate((valid) => {
            const params = {
                name: formObj.name,
                optionType: OptionType.Edit,
                merchantStoreId: formObj.id,
                storeId: formObj.storeId,
                storeNo: formObj.storeNo,
                storeLogoPc: formObj.storeLogoPc,
                storeLogoH5: formObj.storeLogoH5,
                merchantId: AccountService.GetLoginInfo()?.merchantId
            };
            if (valid) {
                ShopSubmit({ ...params }).then(res => {
                    getGoShopDetail();
                    loading.value = false;
                    ElMessage.success(res.resultMsg);
                }).catch((err) => {
                    loading.value = false;
                });
            }
            else {
                return false;
            }
        });
    };
    const copy = () => {
        copyMethod('copy-content');
    };
    const getGoShopDetail = () => {
        GoShopDetail({ id: ShopService.GetCurrentShop().id }).then((res) => {
            formObj.id = res.resultData.id;
            formObj.storeStatus = res.resultData.storeStatus;
            formObj.storeNoCanBeModified = res.resultData.storeNoCanBeModified;
            formObj.merchantStoreId = res.resultData.merchantId;
            formObj.storeId = res.resultData.storeId;
            formObj.name = res.resultData.name;
            formObj.approveInfo = res.resultData.approveInfo;
            formObj.openedTime = FormatterDateTime(res.resultData.openedTime, "yyyy-MM-dd");
            formObj.storeNo = res.resultData.storeNo;
            formObj.storeLogoPc = res.resultData.storeLogoPc;
            formObj.storeLogoH5 = res.resultData.storeLogoH5;
            formObj.storeUrl = res.resultData.storeUrl;
            oldFormData.name = res.resultData.name;
            oldFormData.storeNo = res.resultData.storeNo;
            disabled.value = [
                ShopStatus.NoSaleVerifying,
                ShopStatus.SaleVerifying,
                ShopStatus.Close,
            ].indexOf(formObj.storeStatus) > -1;
        });
    };
    const copyMethod = (id) => {
        const node = document.getElementById(id);
        const createRange = document.createRange();
        createRange.selectNodeContents(node);
        const selection = document.getSelection();
        selection.removeAllRanges();
        selection.addRange(createRange);
        document.execCommand('copy');
        selection.removeAllRanges();
        ElMessage.success('复制成功');
    };
    const giveUp = () => {
        ElMessageBox.confirm("是否放弃修改？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            callback: (res) => {
                if (res == "cancel") {
                    return ElMessage({
                        type: "info",
                        message: "取消放弃修改",
                    });
                }
                loading.value = true;
                GiveUpChange({ id: router.params.id }).then((res) => {
                    loading.value = false;
                    getGoShopDetail();
                });
            },
        });
    };
    const handleInput = (type, event, oldValue) => {
        Debounce(type == 1 ? CheckShopName : CheckStoreNo, event, 500, oldValue);
    };
    const uploadImg = (key, value) => {
        formObj[key] = value.resultData;
    };
    const remove = (key, val) => {
        formObj[key] = '';
    };
    const beforeDetailUpload = (w, h, val) => {
        return new Promise((reslove, reject) => {
            GetImageSize(val).then((res) => {
                if (res.width != w || res.height != h) {
                    ElMessage.error(`图片上传失败，图片大尺寸为${w}*${h}`);
                    reject();
                }
                reslove();
            });
        });
    };
    return {
        ShopStatus,
        form,
        formObj,
        oldFormData,
        uploadUrl,
        rules,
        loading,
        disabled,
        copy,
        submit,
        giveUp,
        getGoShopDetail,
        handleInput,
        uploadImg,
        remove,
        beforeDetailUpload,
    };
}
